/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker, ValidatedTextarea } from '@cp-shared-9/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import {
    CategoryTypeEnum,
    formatOptions,
    getInitialValues,
    RequestInitialValues,
    RequestInitialValuesEnum,
    ShortDescriptionEnum,
    shortDescriptionPlaceholder,
} from './initialValues';
import { validationSchema } from './validationSchema';
import { ContractSelection } from './contract-selection';
import {
    Button,
    ButtonContainer,
    ContentSection,
    Fieldset,
    Heading,
    Layout,
    Modal,
    Spinner,
} from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';

import {
    CatalogOpenRequestResponse,
    CategoryOpenRequestEnum,
    Contract,
    CustomerRequest,
    getCatalogOpenRequestEndpoint,
    getCreateCustomerRequestEndpoint,
    getSendMailEndpoint,
    MailRequest,
    postOpenRequestSalesforceEndpoint,
    ReplacedValues,
} from '@cp-mx/common';
import { CategoryRequestSelection, CategorySelection } from './category-selection';
import { FormikCheckboxGroup } from './checkbox-group';
import { StateSelection } from './state-selection';
import moment from 'moment';
import { useBrandContent, useModelContent } from '../../contracts';
import { useCustomer } from '../../../auth/customer';

enum FinancialProduct {
    credit = 'Credit',
    leasing = 'Leasing',
    premium = 'Premium Credit',
}

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NONE = 'NONE',
}

export const FormView: React.FC<{
    contracts?: Contract[];
}> = ({ contracts }) => {
    useAnalyticsPageViewTracker('requests');
    const { t } = useTranslation('open-request');
    const { data: customerId } = useCustomer();
    const { cmsContent: brandCatalog } = useBrandContent();
    const { cmsContent: modelCatalog } = useModelContent();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const [parentId, setParentId] = useState<string>('');
    const [categoryType, setCategoryType] = useState<string>('');
    let contractProduct = '';

    const [dataCategoryRequest, setDataCategoryRequest] = useState<Array<CatalogOpenRequestResponse>>([]);
    const [dataCategoryTaskCase, setDataCategoryTaskCase] = useState<Array<CatalogOpenRequestResponse>>([]);
    const [isSettingCategoryData, setIsSettingCategoryData] = useState(false);
    const [isSettingTaskCaseData, setIsSettingTaskCaseData] = useState(false);

    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    const mapperRequestServiceCloud = (
        recordTaskCase: CatalogOpenRequestResponse,
        values: RequestInitialValues,
    ): {
        categoryType: string;
        replacedValues: string;
    } => {
        const defaultValue = 'none';
        const documentsCheck = values[RequestInitialValuesEnum.DOCUMENTS_CHECK];
        const documents: Array<string> = [];

        documentsCheck.forEach(document => {
            if (document === recordTaskCase.description) {
                documents.push(recordTaskCase.salesforceValue || '');
            }
        });
        const documentsString = documents.join(';');
        const format = values[RequestInitialValuesEnum.FORMAT_CHECK].join(';');
        const state = values[RequestInitialValuesEnum.STATE_SELECTION];

        let sla = defaultValue;
        if (recordTaskCase.sla) {
            const date = new Date();
            date.setHours(date.getHours() + Number(recordTaskCase.sla));
            sla = date.toISOString();
        }
        let requestTemplate = recordTaskCase.request;
        const shortDescription = values[RequestInitialValuesEnum.SHORT_DESCRIPTION].replace(/\n/g, ' ');
        requestTemplate = requestTemplate
            ?.replace('{{customerRequest}}', shortDescription || defaultValue)
            .replace('{{customerId}}', customerId || defaultValue)
            .replace('{{origin}}', recordTaskCase.origin || defaultValue)
            .replace('{{priority}}', recordTaskCase.priority || defaultValue)
            .replace('{{typification}}', recordTaskCase.typification || defaultValue)
            .replace('{{subTypification}}', recordTaskCase.subTypification || defaultValue)
            .replace('{{type}}', recordTaskCase.type || defaultValue)
            .replace('{{recordTypeId}}', recordTaskCase.recordTypeId || defaultValue)
            .replace('{{salesforceOwner}}', recordTaskCase.salesforceOwner || defaultValue)
            .replace('{{SLA}}', sla)
            .replace('{{actionCode}}', recordTaskCase.actionCode || defaultValue)
            .replace('{{documents}}', documentsString || defaultValue)
            .replace('{{formatDocument}}', format || defaultValue)
            .replace('{{state}}', state || defaultValue)
            .replace('{{contractId}}', values[RequestInitialValuesEnum.CONTRACT_SELECTION]);
        return { replacedValues: requestTemplate || '', categoryType: recordTaskCase.category };
    };

    /**
     * Function for data replacement in request
     * @param values {RequestInitialValues}
     */
    const replaceRequestServiceCloud = (values: RequestInitialValues): Array<ReplacedValues> => {
        let recordTaskCase = {} as CatalogOpenRequestResponse;
        let isDocumentTask = false;
        if (categoryType === CategoryTypeEnum.SECTION) {
            const document = values[RequestInitialValuesEnum.DOCUMENTS_CHECK][0];
            recordTaskCase = dataCategoryTaskCase?.find(
                elem => elem.description === document,
            ) as CatalogOpenRequestResponse;
            isDocumentTask = recordTaskCase.category === 'task';
            const documents = values[RequestInitialValuesEnum.DOCUMENTS_CHECK];
            const docsMapper = documents.map(document => {
                recordTaskCase = dataCategoryTaskCase?.find(
                    elem => elem.description === document,
                ) as CatalogOpenRequestResponse;
                return mapperRequestServiceCloud(recordTaskCase, values);
            });
            if (!isDocumentTask) {
                const templateDocs = JSON.parse(docsMapper[0].replacedValues);
                let stringDocuments = '';
                docsMapper.map(doc => {
                    const request = JSON.parse(doc.replacedValues);
                    stringDocuments = stringDocuments + `${request['Documentos_RD_VWL__c']};`;
                });
                templateDocs['Documentos_RD_VWL__c'] = stringDocuments;
                return [
                    {
                        categoryType: docsMapper[0].categoryType,
                        replacedValues: JSON.stringify(templateDocs),
                    },
                ];
            } else {
                return docsMapper;
            }
        } else {
            recordTaskCase = dataCategoryRequest?.find(
                elem => elem.description === values[RequestInitialValuesEnum.CATEGORY_TYPE_SELECTION],
            ) as CatalogOpenRequestResponse;
            return [mapperRequestServiceCloud(recordTaskCase, values)];
        }
    };

    const mapperCustomerRequest = (values: RequestInitialValues): CustomerRequest => {
        const contractSelected = contracts?.find(contract => contract.contractLabel === values.contractSelection);
        const brandSelected = contractSelected?.vehicle.brand as string;
        const modelSelected = contractSelected?.vehicle.model as string;
        const brand = brandCatalog && brandCatalog[brandSelected] ? brandCatalog[brandSelected] : brandSelected;
        const model = modelCatalog && modelCatalog[modelSelected] ? modelCatalog[modelSelected] : modelSelected;
        return {
            requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
            requestType: 'contractRequest',
            requestCategory: values.categoryMainSelection,
            shortDescription: values.inputField1,
            requestText: values.categoryTypeSelection,
            contractNumber: values.contractSelection,
            financialProduct: contractSelected?.product.type.trim() as FinancialProduct,
            carModel: `${brand} ${model}`,
        };
    };
    const mapperMailRequest = (values: RequestInitialValues): MailRequest => {
        return {
            contractNumber: values.contractSelection,
            categoryName: values.categoryMainSelection,
            shortDescription: values.inputField1,
            request: values.categoryTypeSelection,
        };
    };

    const handleSubmit = (values: RequestInitialValues, { resetForm }: { resetForm: Function }): void => {
        (async (): Promise<void> => {
            setIsSubmitting(true);
            const categorySelection = values.categoryMainSelection;
            const mappingResult = await mapperMailRequest(values);
            const customerRequestMapping = mapperCustomerRequest(values);
            const requestGroup = replaceRequestServiceCloud(values);
            const requestForSalesforce: Array<boolean> = [];
            for (const request of requestGroup) {
                const category =
                    request.categoryType === 'task' ? CategoryOpenRequestEnum.TASK : CategoryOpenRequestEnum.CASE;
                await CpDataApi.post(getCreateCustomerRequestEndpoint(), customerRequestMapping)
                    .then(response => {
                        const salesforceRequest = request.replacedValues?.replace('{{requestId}}', response.data.id);
                        return CpDataApi.post(postOpenRequestSalesforceEndpoint(category), { salesforceRequest });
                    })
                    .then(() => {
                        setMessageType(MessageType.SUCCESS);
                        onSuccess(categorySelection);
                        setIsSubmitting(false);
                        resetForm();
                        setDataCategoryRequest([]);
                        setDataCategoryTaskCase([]);
                        requestForSalesforce.push(true);
                    })
                    .catch(() => {
                        setMessageType(MessageType.ERROR);
                        onError(categorySelection);
                        setIsSubmitting(false);
                        requestForSalesforce.push(false);
                    });
            }

            if (requestForSalesforce.some((requestSuccess) => requestSuccess)) {
                CpDataApi.post(getSendMailEndpoint(), mappingResult);
            }
        })();
        setMessageType(MessageType.SUCCESS);
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalClose = (resetForm: Function): void => {
        resetForm({ values: getInitialValues() });
        handleModalClose();
    };

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    useEffect(() => {
        if (parentId) {
            if (categoryType === CategoryTypeEnum.SECTION) {
                setIsSettingTaskCaseData(true);
            } else {
                setIsSettingCategoryData(true);
            }
            CpDataApi.get(`${getCatalogOpenRequestEndpoint()}?parentId=${parentId}`)
                .then(response => {
                    if (categoryType === CategoryTypeEnum.SECTION) {
                        setDataCategoryTaskCase(response.data);
                        setIsSettingTaskCaseData(false);
                    } else {
                        const categoryRequest = response.data.filter((elem: CatalogOpenRequestResponse) =>
                            elem.products.includes(contractProduct.trim()),
                        );
                        setDataCategoryRequest(categoryRequest);
                        setDataCategoryTaskCase([]);
                        setIsSettingCategoryData(false);
                    }
                    onSuccess();
                    setParentId('');
                })
                .catch(() => {
                    onError();
                    setIsSettingTaskCaseData(false);
                    setIsSettingCategoryData(false);
                    setParentId('');
                });
        }
    }, [parentId]);

    const shortDescription = (values: RequestInitialValues) => {
        let placeholder = '';
        if (
            values.categoryMainSelection === 'Seguro y siniestros' &&
            values.categoryTypeSelection === 'Solicitud de cambio de aseguradora'
        ) {
            const key = values.categoryTypeSelection as ShortDescriptionEnum;
            placeholder = shortDescriptionPlaceholder[key];
        } else {
            const key = values.categoryMainSelection as ShortDescriptionEnum;
            placeholder = shortDescriptionPlaceholder[key] || t('fields.inputField1.placeholder.default');
        }
        return (
            <ValidatedTextarea
                label={t('fields.inputField1.label')}
                placeholder={placeholder}
                name={'inputField1'}
                maxLength={1000}
                isMandatory
                testId={'inputField1-test'}
            />
        );
    };

    const getContractProduct = (values: RequestInitialValues) => {
        const contractSelected = contracts?.find(contract => contract.contractLabel === values.contractSelection);
        contractProduct = contractSelected?.product.type as string;
        return contractProduct;
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema(t, categoryType)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ values, submitForm, resetForm }): JSX.Element => (
                <Form onSubmit={(e): void => e.preventDefault()} data-testid="request-form">
                    <ContentSection pageWrapSize="medium" className={'request-certificate-form'}>
                        <Heading level={1} className="u-mb-large">
                            {t('headline')}
                        </Heading>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            resetForm={resetForm}
                                            currentContractNumber={values.contractSelection}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <CategorySelection
                                            currentCategorySelection={values.categoryMainSelection}
                                            setParentId={setParentId}
                                            setCategoryType={setCategoryType}
                                            contractProduct={getContractProduct(values)}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        {isSettingCategoryData ? (
                                            <Spinner center />
                                        ) : (
                                            <CategoryRequestSelection
                                                currentCategorySelection={values.categoryTypeSelection}
                                                dataCategoryRequest={dataCategoryRequest}
                                                setParentId={setParentId}
                                                setCategoryType={setCategoryType}
                                            />
                                        )}
                                    </Layout.Item>
                                    {values.categoryTypeSelection === 'Solicitud de documentos de arrendamiento' && (
                                        <Layout.Item className="u-1/2 u-1/1@m u-1/1@s 1/1@xs">
                                            <StateSelection currentStateSelection={values.stateSelection} />
                                        </Layout.Item>
                                    )}
                                    {[
                                        'Solicitud de documentos generales',
                                        'Solicitud de documentos de arrendamiento',
                                    ].includes(values.categoryTypeSelection) && (
                                        <Layout.Item className="u-1/2 u-1/1@m u-1/1@s 1/1@xs">
                                            <FormikCheckboxGroup
                                                name={RequestInitialValuesEnum.FORMAT_CHECK}
                                                label={t('fields.formatCheck.label')}
                                                options={formatOptions}
                                                spacing="u-1/2 u-1/2@m u-1/1@s 1/1@xs"
                                            />
                                        </Layout.Item>
                                    )}
                                    <Layout.Item>
                                        {categoryType === CategoryTypeEnum.SECTION && values.categoryTypeSelection ? (
                                            isSettingTaskCaseData ? (
                                                <Spinner center />
                                            ) : (
                                                <FormikCheckboxGroup
                                                    name={RequestInitialValuesEnum.DOCUMENTS_CHECK}
                                                    label={t('fields.documentsCheck.label')}
                                                    options={dataCategoryTaskCase}
                                                    border
                                                />
                                            )
                                        ) : null}
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>{shortDescription(values)}</Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        testId={'dashboard-button'}
                                        onClick={(): void => {
                                            history.push(dashboardPagePath());
                                        }}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <Button
                                        testId={'submit-button'}
                                        type="submit"
                                        onClick={async (): Promise<void> => {
                                            await submitForm();
                                        }}
                                    >
                                        {t('buttons.submit')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    {isSubmitting ? (
                        <Spinner center fullPage />
                    ) : (
                        <>
                            <Modal
                                shown={messageType === MessageType.ERROR}
                                status="error"
                                title={t('notifications.error.headline')}
                                onClose={handleModalClose}
                                onCancel={handleModalClose}
                                onClickOutside={handleModalClose}
                                buttonCancelText={t('notifications.error.button')}
                                testId={'error-modal'}
                            >
                                <p className="u-text-left">
                                    <b className="u-font-size-fs4 u-mv u-text-brand">
                                        {t('notifications.error.message')}
                                    </b>
                                </p>
                            </Modal>
                            <Modal
                                shown={messageType === MessageType.SUCCESS}
                                status="success"
                                title={t('notifications.success.headline')}
                                onClose={handleSuccessModalConfirm}
                                onConfirm={handleSuccessModalConfirm}
                                onClickOutside={(): void => handleSuccessModalClose(resetForm)}
                                testId={'success-modal'}
                            >
                                <p className="u-text-left">
                                    <b className="u-font-size-fs4 u-mv u-text-brand">
                                        {t('notifications.success.message')}
                                    </b>
                                </p>
                            </Modal>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};
